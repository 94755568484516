import { useCallback, useEffect, useMemo, useState } from "react";
import * as style from "./style";
import { FaEye, FaEyeSlash, FaHome } from "react-icons/fa";
import { useFormik } from "formik";
import { addClient, getBrokerages } from "../../../services/admin.service";
import { useDispatch } from "react-redux";
import { openToast } from "../../../redux/slice/toastSlice";
import CustomLoader from "../../../components/loader/CustomLoader";
import { Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { IoMdArrowRoundBack } from "react-icons/io";
import isEmpty from "is-empty";

const AddClient = () => {
  const [showKey, setShowKey] = useState(false);
  const [showSecret, setShowSecret] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [brokerageNames, setBrokerageNames] = useState([]);
  const [activeTab, setActiveTab] = useState({});
  const [click, setClick] = useState(false);
  const [selectedTabs, setSelectedTabs] = useState([]);
  const [formValues, setFormValues] = useState({});
  const [interactedTabs, setInteractedTabs] = useState({});
  const [submitCount, setSubmitCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [loader, setLoader] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Memoized variable for mapping brokerage names with their Id
  const BrokeragesWithId = useMemo(
    () => brokerageNames?.map((name) => ({ name: name?.name, id: name?._id })),
    [brokerageNames]
  );

  // used to validate the static fields and dynamic fields
  const validate = (values) => {
    const errors = {};
    let newInteractedTabs = { ...interactedTabs };

    filteredTabs?.forEach((platform) => {
      if (platform.name === activeTab.name) {
        const platformName = platform.name;
        const fields = [
          { name: `${platformName}DisplayName`, label: "Display Name" },
          { name: `${platformName}Capital`, label: "Capital" },
          { name: `${platformName}Margin`, label: "Margin" },
          { name: `${platformName}RegE-mail`, label: "Reg E-mail" },
          { name: `${platformName}RegPassword`, label: "Reg Password" },
          { name: `${platformName}ApiKey`, label: "Api Key" },
          { name: `${platformName}ApiSecret`, label: "Api Secret" },
          { name: `${platformName}ApiExpiry`, label: "Api Expiry" },
          { name: `${platformName}SessionToken`, label: "Session Token" },
        ];

        fields.forEach(({ name, label }) => {
          newInteractedTabs[name] = "";
          if (!values[name]) {
            errors[name] = `${label} is required`;
          }
        });
      }
    });

    setInteractedTabs(newInteractedTabs);
    return errors;
  };

  // This function was used to toggle visibility for Api key, Api secret, and password
  const toggleVisibility = useCallback((key) => {
    if (key === "key") {
      setShowKey((prev) => !prev);
    } else if (key === "pass") {
      setShowPassword((prev) => !prev);
    } else if (key === "secret") {
      setShowSecret((prev) => !prev);
    }
  }, []);

  // This effect was used to add the active tab to the selected tab state
  useEffect(() => {
    if (
      activeTab.id !== "" &&
      !selectedTabs.some((tab) => tab.id === activeTab.id)
    ) {
      setSelectedTabs((prevTabs) => [...prevTabs, activeTab]);
    }
  }, [activeTab]);

  // It will filter the empty brokerId if empty string occured it will eleminate that object
  const filteredTabs = selectedTabs.filter(
    (tab) => Object.keys(tab).length !== 0
  );

  // This effect hook was used to add the static and dynamic initial values
  useEffect(() => {
    const initialFormValues = {};

    filteredTabs?.forEach((platform) => {
      initialFormValues[`${platform.name}DisplayName`] = "";
      initialFormValues[`${platform.name}Capital`] = "";
      initialFormValues[`${platform.name}Margin`] = 0;
      initialFormValues[`${platform.name}RegE-mail`] = "";
      initialFormValues[`${platform.name}RegPassword`] = "";
      initialFormValues[`${platform.name}ApiKey`] = "";
      initialFormValues[`${platform.name}ApiSecret`] = "";
      initialFormValues[`${platform.name}ApiExpiry`] = "";
      initialFormValues[`${platform.name}SessionToken`] = "";
    });

    setFormValues(initialFormValues);
  }, [submitCount]);

  // Formik hook for form handling
  const formik = useFormik({
    initialValues: formValues,
    validate,
    onSubmit: async (values, { setErrors }) => {
      try {
        setLoader(true);

        formik.setTouched(
          Object.keys(values).reduce((acc, key) => {
            acc[key] = true;
            return acc;
          }, {})
        );

        const activeBrokerage = BrokeragesWithId?.find(
          (brokerage) => brokerage.name === activeTab.name
        );

        if (activeBrokerage) {
          const credentials = {
            brokerage_id: activeBrokerage.id,
            display_name: values[`${activeBrokerage.name}DisplayName`] || "",
            capital: values[`${activeBrokerage.name}Capital`] || "",
            margin: values[`${activeBrokerage.name}Margin`] || 0,
            official_email: values[`${activeBrokerage.name}RegE-mail`] || "",
            official_pass: values[`${activeBrokerage.name}RegPassword`] || "",
            api_key: values[`${activeBrokerage.name}ApiKey`] || "",
            api_secret: values[`${activeBrokerage.name}ApiSecret`] || "",
            api_key_expiration:
              values[`${activeBrokerage.name}ApiExpiry`] || "",
            session_token: values[`${activeBrokerage.name}SessionToken`] || "",
          };

          const response = await addClient(credentials);
          if (response?.success) {
            setLoader(false);
            formik.resetForm();
            setSubmitCount((prevCount) => prevCount + 1);
          } else {
            setLoader(false);
            if (!isEmpty(response.errors)) {
              const fieldErrors = {};
              Object.keys(response.errors).forEach((key) => {
                let formikFieldKey = "";

                switch (key) {
                  case "display_name":
                    formikFieldKey = `${activeBrokerage.name}DisplayName`;
                    break;
                  case "capital":
                    formikFieldKey = `${activeBrokerage.name}Capital`;
                    break;
                  case "margin":
                    formikFieldKey = `${activeBrokerage.name}Margin`;
                    break;
                  case "official_email":
                    formikFieldKey = `${activeBrokerage.name}RegE-mail`;
                    break;
                  case "official_pass":
                    formikFieldKey = `${activeBrokerage.name}RegPassword`;
                    break;
                  case "api_key":
                    formikFieldKey = `${activeBrokerage.name}ApiKey`;
                    break;
                  case "api_secret":
                    formikFieldKey = `${activeBrokerage.name}ApiSecret`;
                    break;
                  case "api_key_expiration":
                    formikFieldKey = `${activeBrokerage.name}ApiExpiry`;
                    break;
                  case "session_token":
                    formikFieldKey = `${activeBrokerage.name}SessionToken`;
                    break;
                  default:
                    break;
                }

                if (formikFieldKey) {
                  fieldErrors[formikFieldKey] = response.errors[key];
                }
              });
              setErrors(fieldErrors);
            }

            response?.message &&
              dispatch(
                openToast({
                  message: response?.message || "Something went wrong",
                  type: "error",
                })
              );
          }
        } else {
          setLoader(false);
          dispatch(
            openToast({
              message: "No active tab found",
              type: "error",
            })
          );
        }
      } catch (error) {
        setLoader(false);
        console.error("error: ", error);
        dispatch(
          openToast({
            message: "Something went wrong",
            type: "error",
          })
        );
      }
    },
    enableReinitialize: true,
  });

  // Function for fetching data from the Api
  const fetchData = useCallback(async () => {
    const response = await getBrokerages();
    setBrokerageNames(response?.result);
    setLoading(false);
  }, []);

  const handelClickHome = () => {
    navigate("/dashboard");
  };

  // This effect hook was used to call the fetchData function whenever the page rendered
  useEffect(() => {
    fetchData();
  }, [fetchData]);

  // This effect will set the active tab
  useEffect(() => {
    if (BrokeragesWithId?.length > 0 && !click) {
      setActiveTab({
        name: BrokeragesWithId[0].name,
        id: BrokeragesWithId[0].id,
      });
    }
  }, [BrokeragesWithId, click]);

  // This function used for handling dropdown change
  const handleDropdownChange = useCallback(
    (event) => {
      const selectedBrokerage = BrokeragesWithId.find(
        (brokerage) => brokerage.id === event.target.value
      );
      setClick(true);
      if (selectedBrokerage) {
        setActiveTab(selectedBrokerage);
      }
    },
    [BrokeragesWithId]
  );

  const renderInput = useCallback(
    (label, platform) => (
      <style.UserContainer key={label}>
        <style.UserRightText>{label}</style.UserRightText>
        {label === "Api Key" ||
        label === "Api Secret" ||
        label === "Reg Password" ? (
          <style.ApiInputWrapper>
            <style.InputContainer>
              <style.Input
                name={`${platform}${label.replace(" ", "")}`}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values[`${platform}${label.replace(" ", "")}`]}
                type={
                  label === "Api Key"
                    ? showKey
                      ? "text"
                      : "password"
                    : label === "Reg Password"
                    ? showPassword
                      ? "text"
                      : "password"
                    : showSecret
                    ? "text"
                    : "password"
                }
              />
              <style.Border className="border" />
              <style.EyeButton
                onClick={() =>
                  toggleVisibility(
                    label === "Api Key"
                      ? "key"
                      : label === "Reg Password"
                      ? "pass"
                      : "secret"
                  )
                }
                className="micButton"
              >
                {label === "Api Key" ? (
                  showKey ? (
                    <FaEye />
                  ) : (
                    <FaEyeSlash />
                  )
                ) : label === "Reg Password" ? (
                  showPassword ? (
                    <FaEye />
                  ) : (
                    <FaEyeSlash />
                  )
                ) : showSecret ? (
                  <FaEye />
                ) : (
                  <FaEyeSlash />
                )}
              </style.EyeButton>
            </style.InputContainer>
            {(formik.touched[`${platform}${label.replace(" ", "")}`] ||
              formik.submitCount > 0) &&
              formik.errors[`${platform}${label.replace(" ", "")}`] && (
                <style.FormikError>
                  {formik.errors[`${platform}${label.replace(" ", "")}`]}
                </style.FormikError>
              )}
          </style.ApiInputWrapper>
        ) : (
          <style.ApiInputWrapper>
            <style.RightUserInput
              name={`${platform}${label.replace(" ", "")}`}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values[`${platform}${label.replace(" ", "")}`]}
              type={
                label === "Api Expiry"
                  ? "date"
                  : label === "Margin"
                  ? "number"
                  : "text"
              }
            />
            {(formik.touched[`${platform}${label.replace(" ", "")}`] ||
              formik.submitCount > 0) &&
              formik.errors[`${platform}${label.replace(" ", "")}`] && (
                <style.FormikError>
                  {formik.errors[`${platform}${label.replace(" ", "")}`]}
                </style.FormikError>
              )}
          </style.ApiInputWrapper>
        )}
      </style.UserContainer>
    ),
    [formik, showKey, showSecret, toggleVisibility]
  );

  return (
    <>
      <div>
        <style.HeadingWrapper>
          <style.Heading>Add Client</style.Heading>
          <div className="flex gap-2">
            <button
              className="items-center button-style h-8 px-2 text-white bg-gray-900 font-OpenSans text-base border-none cursor-pointer focus:outline-none button-style"
              onClick={() => navigate(-1)}
            >
              <div className="flex items-center justify-center">
                <div>
                  <IoMdArrowRoundBack className="mr-1 align-middle" />
                </div>
                <div>Back</div>
              </div>
            </button>

            <button
              className="items-center h-8 px-2 text-white bg-gray-900 font-OpenSans text-base border-none cursor-pointer focus:outline-none button-style"
              onClick={handelClickHome}
            >
              <div className="flex items-center justify-center">
                <div>
                  <FaHome className="mr-1 align-middle" />
                </div>
                <div>Home</div>
              </div>
            </button>
          </div>
        </style.HeadingWrapper>
        <style.Container>
          <style.RightContainer>
            {loading ? (
              <style.LoaderWrapper>
                <CustomLoader />
              </style.LoaderWrapper>
            ) : (
              <div>
                <style.Dropdown
                  onChange={handleDropdownChange}
                  value={activeTab.id}
                >
                  {BrokeragesWithId?.map((brokerage) => (
                    <option key={brokerage?.id} value={brokerage?.id}>
                      {brokerage?.name}
                    </option>
                  ))}
                </style.Dropdown>

                {brokerageNames?.map((brokerage) => (
                  <style.TabContent
                    key={brokerage?.id}
                    isActive={activeTab.name === brokerage?.name}
                  >
                    {[
                      "Display Name",
                      "Capital",
                      "Margin",
                      "Reg E-mail",
                      "Reg Password",
                      "Api Key",
                      "Api Secret",
                      "Api Expiry",
                      "Session Token",
                    ].map((label) => renderInput(label, brokerage?.name))}
                  </style.TabContent>
                ))}
                <style.UserContainer>
                  <style.UserRightText></style.UserRightText>
                  <style.SubmitButton
                    type="submit"
                    className="button-style"
                    onClick={formik.handleSubmit}
                  >
                    {loader ? <Spinner size="sm" /> : "Create"}
                  </style.SubmitButton>
                </style.UserContainer>
              </div>
            )}
          </style.RightContainer>
        </style.Container>
      </div>
    </>
  );
};

export default AddClient;
