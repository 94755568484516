let config = {
  GOOGLE_OAUTH_ID: "457448460143-g8eo3o4i8ttkprcb9ea2rlptq36hc8bk.apps.googleusercontent.com",
  API_URL: "http://localhost:5000/api",
  SOCKET_URL: "http://localhost:5000",
};

// process.env.REACT_APP_MODE === "PROD"

if (true) {
  config = {
    ...config,
    API_URL: "https://sa-investmentadvisors-backend.net/api",
    SOCKET_URL: "https://sa-investmentadvisors-backend.net",
    GOOGLE_OAUTH_ID: "624289875874-fdg1i045i026s5fkc0tegggmp628tpn5.apps.googleusercontent.com",
  };
}

export default config;
