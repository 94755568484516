import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as style from "./style";
import { getBrokerages, addIndex } from "../../services/admin.service";
import { Container, Row, Col, Spinner } from "react-bootstrap";
import { openToast } from "../../redux/slice/toastSlice";
import { useDispatch } from "react-redux";
import CustomLoader from "../../components/loader/CustomLoader";
import { FaHome } from "react-icons/fa";
import { IoMdArrowRoundBack } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import isEmpty from "is-empty";

const AddIndexPage = () => {
  const [data, setData] = useState([]);
  const [symbols, setSymbols] = useState({});
  const [loading, setLoading] = React.useState(true);
  const [loader, setLoader] = React.useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      const response = await getBrokerages();
      setData(response?.result);
      if (response && response?.result) {
        const initialSymbols = response?.result.reduce((acc, brokerage) => {
          if (acc && brokerage?._id) {
            acc[brokerage?._id] = "";
            acc[brokerage._id] = "";
          }
        }, {});
        setSymbols(initialSymbols);
        setLoading(false);
      } else {
        return;
      }
    };
    fetchData();
  }, []);

  const validate = (values) => {
    const errors = {};
    if (!values.name) {
      errors.name = "Required";
    }

    if (!values.identifier) {
      errors.identifier = "Required";
    }

    if (!values.max_order_quantity) {
      errors.max_order_quantity = "Required";
    }

    if (!values.custom_max_order_quantity) {
      errors.custom_max_order_quantity = "Required";
    }

    if (!values.lot_size) {
      errors.lot_size = "Required";
    }

    if (!values.instrument_token) {
      errors.instrument_token = "Required";
    }

    if (!values.margin) {
      errors.margin = "Required";
    }

    if (!values.instrument_tracker) {
      errors.instrument_tracker = "Required";
    }

    if (!values.dataname) {
      errors.dataname = "Required";
    }

    return errors;
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      dataname: "",
      identifier: "",
      max_order_quantity: "",
      custom_max_order_quantity: "",
      lot_size: 0,
      custom_lot_size: 0,
      instrument_token: "",
      instrument_tracker: "",
      margin: 0,
      exchange_code: "",
      symbols: symbols,
    },
    validate,
    onSubmit: async (values, { setErrors }) => {
      try {
        setLoader(true);
        const formattedData = {
          ...values,
          max_order_quantity: parseInt(values.max_order_quantity),
          custom_max_order_quantity: parseInt(values.custom_max_order_quantity),
          lot_size: parseInt(values.lot_size),
          custom_lot_size: 0,
          symbols: values.symbols,
          exchange_code: values.exchange_code.toUpperCase()
        };

        const response = await addIndex(formattedData);
        if (response?.success) {
          setLoader(false);
          // Success Toast
          // dispatch(
          //   openToast({
          //     message: message,
          //     type: "success",
          //   })
          // );
          formik.resetForm();
        } else {
          setLoader(false);

          if (!isEmpty(response.errors)) {
            const fieldErrors = {};
            Object.keys(response.errors).forEach((key) => {
              fieldErrors[key] = response.errors[key];
            });
            setErrors(fieldErrors);
          }
          // Error Toast
          response?.message &&
            dispatch(
              openToast({
                message: response?.message,
                type: "error",
              })
            );
        }
      } catch (error) {
        setLoader(false);
        console.error("error: ", error);
        dispatch(
          openToast({
            message: "Something went wrong",
            type: "error",
          })
        );
      }
    },
  });

  const handleClickHome = () => {
    navigate("/dashboard");
  };

  return (
    <div>
      <style.Container>
        <Container fluid>
          <div>
            <Row>
              {loading ? (
                <style.LoaderWrapper>
                  <CustomLoader />
                </style.LoaderWrapper>
              ) : (
                <Col xs={12} md={3.5} lg={4}>
                  <div className="flex items-center mb-4 h-8">
                    <style.Heading>Symbols</style.Heading>
                  </div>
                  {data?.map((brokerage) => (
                    <style.BrokerageContainer key={brokerage?._id}>
                      <div>
                        <style.BrokerageText className="all-normal-text">
                          {brokerage?.name || ""}
                        </style.BrokerageText>
                      </div>
                      <div>
                        <style.Input
                          type="text"
                          className="all-normal-text"
                          name={`symbols.${brokerage?._id}`}
                          onChange={(event) => {
                            formik.setFieldValue(
                              `symbols.${brokerage?._id}`,
                              event.target.value
                            );
                          }}
                          value={formik.values.symbols[brokerage._id]}
                          onBlur={formik.handleBlur}
                        />
                      </div>
                    </style.BrokerageContainer>
                  ))}
                </Col>
              )}
              <Col xs={12} md={8.5} lg={8}>
                <div className="flex justify-between items-center mb-4">
                  <style.Heading>Add Index</style.Heading>
                  <div className="flex gap-2">
                    <button
                      className="items-center button-style h-8 px-2 text-white bg-gray-900 font-OpenSans text-base border-none cursor-pointer focus:outline-none button-style"
                      onClick={() => navigate(-1)}
                    >
                      <div className="flex items-center justify-center">
                        <div>
                          <IoMdArrowRoundBack className="mr-1 align-middle" />
                        </div>
                        <div>Back</div>
                      </div>
                    </button>

                    <button
                      className="items-center h-8 px-2 text-white bg-gray-900 font-OpenSans text-base border-none cursor-pointer focus:outline-none button-style"
                      onClick={handleClickHome}
                    >
                      <div className="flex items-center justify-center">
                        <div>
                          <FaHome className="mr-1 align-middle" />
                        </div>
                        <div>Home</div>
                      </div>
                    </button>
                  </div>
                </div>
                <Row>
                  <Col xs={12} sm={6}>
                    <style.BrokerageContainer>
                      <div>
                        <style.BrokerageText>Name</style.BrokerageText>
                      </div>
                      <div>
                        <style.Input
                          type="text"
                          name="name"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.name}
                        />
                        {formik.touched.name && formik.errors.name ? (
                          <div className="form-error">{formik.errors.name}</div>
                        ) : null}
                      </div>
                    </style.BrokerageContainer>
                  </Col>

                  <Col xs={12} sm={6}>
                    <style.BrokerageContainer>
                      <div>
                        <style.BrokerageText>
                          Datapoint Name (To fetch data from GROWW)
                        </style.BrokerageText>
                      </div>
                      <div>
                        <style.Input
                          type="text"
                          name="dataname"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.dataname}
                        />
                        {formik.touched.dataname && formik.errors.dataname ? (
                          <div className="form-error">
                            {formik.errors.dataname}
                          </div>
                        ) : null}
                      </div>
                    </style.BrokerageContainer>
                  </Col>

                  <Col xs={12} sm={6}>
                    <style.BrokerageContainer>
                      <div>
                        <style.BrokerageText>Identifier</style.BrokerageText>
                      </div>
                      <div>
                        <style.Input
                          type="text"
                          name="identifier"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.identifier}
                        />
                        {formik.touched.identifier &&
                        formik.errors.identifier ? (
                          <div className="form-error">
                            {formik.errors.identifier}
                          </div>
                        ) : null}
                      </div>
                    </style.BrokerageContainer>
                  </Col>

                  <Col xs={12} sm={6}>
                    <style.BrokerageContainer>
                      <div>
                        <style.BrokerageText>
                          NSE Max Order Quantity
                        </style.BrokerageText>
                      </div>
                      <div>
                        <style.Input
                          type="number"
                          name="max_order_quantity"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.max_order_quantity}
                        />
                        {formik.touched.max_order_quantity &&
                        formik.errors.max_order_quantity ? (
                          <div className="form-error">
                            {formik.errors.max_order_quantity}
                          </div>
                        ) : null}
                      </div>
                    </style.BrokerageContainer>
                  </Col>

                  <Col xs={12} sm={6}>
                    <style.BrokerageContainer>
                      <div>
                        <style.BrokerageText>
                          Custom NSE Max Order Quantity
                        </style.BrokerageText>
                      </div>
                      <div>
                        <style.Input
                          type="number"
                          name="custom_max_order_quantity"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.custom_max_order_quantity}
                        />
                        {formik.touched.custom_max_order_quantity &&
                        formik.errors.custom_max_order_quantity ? (
                          <div className="form-error">
                            {formik.errors.custom_max_order_quantity}
                          </div>
                        ) : null}
                      </div>
                    </style.BrokerageContainer>
                  </Col>

                  <Col xs={12} sm={6}>
                    <style.BrokerageContainer>
                      <div>
                        <style.BrokerageText>Lot Size</style.BrokerageText>
                      </div>
                      <div>
                        <style.Input
                          type="number"
                          name="lot_size"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.lot_size}
                        />
                        {formik.touched.lot_size && formik.errors.lot_size ? (
                          <div className="form-error">
                            {formik.errors.lot_size}
                          </div>
                        ) : null}
                      </div>
                    </style.BrokerageContainer>
                  </Col>

                  <Col xs={12} sm={6}>
                    <style.BrokerageContainer>
                      <div>
                        <style.BrokerageText>
                          Instrument Token
                        </style.BrokerageText>
                      </div>
                      <div>
                        <style.Input
                          type="text"
                          name="instrument_token"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.instrument_token}
                        />
                        {formik.touched.instrument_token &&
                        formik.errors.instrument_token ? (
                          <div className="form-error">
                            {formik.errors.instrument_token}
                          </div>
                        ) : null}
                      </div>
                    </style.BrokerageContainer>
                  </Col>

                  <Col xs={12} sm={6}>
                    <style.BrokerageContainer>
                      <div>
                        <style.BrokerageText>
                          Instrument Tracker
                        </style.BrokerageText>
                      </div>
                      <div>
                        <style.Select
                          type="text"
                          name="instrument_tracker"
                          className="all-normal-text"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.instrument_tracker}
                        >
                          <option className="all-normal-text" value="">
                            Select Tracker
                          </option>
                          {data?.map((brokerage, index) => (
                            <option
                              className="all-normal-text"
                              key={index}
                              value={brokerage._id}
                            >
                              {brokerage?.name || " "}
                            </option>
                          ))}
                        </style.Select>
                        {formik.touched.instrument_tracker &&
                        formik.errors.instrument_tracker ? (
                          <div className="form-error">
                            {formik.errors.instrument_tracker}
                          </div>
                        ) : null}
                      </div>
                    </style.BrokerageContainer>
                  </Col>

                  <Col xs={12} sm={6}>
                    <style.BrokerageContainer>
                      <div>
                        <style.BrokerageText>Margin</style.BrokerageText>
                      </div>
                      <div>
                        <style.Input
                          type="number"
                          name="margin"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.margin}
                        />
                        {formik.touched.margin && formik.errors.margin ? (
                          <div className="form-error">
                            {formik.errors.margin}
                          </div>
                        ) : null}
                      </div>
                    </style.BrokerageContainer>
                  </Col>
                  <Col xs={12} sm={6}>
                    <style.BrokerageContainer>
                      <div>
                        <style.BrokerageText>Exchange Code</style.BrokerageText>
                      </div>
                      <div>
                        <style.Input
                          type="text"
                          name="exchange_code"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.exchange_code}
                        />
                        {formik.touched.exchange_code &&
                        formik.errors.exchange_code ? (
                          <div className="form-error">
                            {formik.errors.exchange_code}
                          </div>
                        ) : null}
                      </div>
                    </style.BrokerageContainer>
                  </Col>
                </Row>
                <style.ButtonWrapper>
                  <style.SubmitButton
                    className="button-style"
                    onClick={formik.handleSubmit}
                  >
                    {loader ? <Spinner size="sm" /> : "Create"}
                  </style.SubmitButton>
                </style.ButtonWrapper>
              </Col>
            </Row>
          </div>
        </Container>
      </style.Container>
    </div>
  );
};

export default AddIndexPage;
