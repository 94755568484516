import axios, { handleResponse } from "./Axios";

export const addClient = async (data) => {
  try {
    const responce = await axios({
      url: "/v1/admin/client",
      method: "POST",
      data,
    });
    return handleResponse(responce, "success");
  } catch (error) {
    console.error("error: ", error);
    return handleResponse(error, "error");
  }
};

export const addUser = async (data) => {
  try {
    const responce = await axios({
      url: "/v1/admin/user",
      method: "POST",
      data,
    });
    return handleResponse(responce, "success");
  } catch (error) {
    console.error("error: ", error);
    return handleResponse(error, "error");
  }
};

export const getUser = async() => {
  try {
    const responce = await axios({
      url: "/v1/user/all-users",
      method: "GET",
    });
    return handleResponse(responce, "success");
  } catch (error) {
    console.error("error: ", error);
    return handleResponse(error, "error");
  }
}

export const getBrokerages = async () => {
  try {
    const responce = await axios({
      url: "/v1/admin/broker",
      method: "GET",
    });
    return handleResponse(responce, "success");
  } catch (error) {
    console.error("error: ", error);
    return handleResponse(error, "error");
  }
};

export const getClient = async () => {
  try {
    const responce = await axios({
      url: "/v1/admin/client",
      method: "GET",
    });
    return handleResponse(responce, "success");
  } catch (error) {
    console.error("error: ", error);
    return handleResponse(error, "error");
  }
};

export const getAllClient = async () => {
  try {
    const responce = await axios({
      url: "/v1/admin/user",
      method: "GET",
    });
    console.log('responce: ', responce);
    return handleResponse(responce, "success");
  } catch (error) {
    console.error("error: ", error);
    return handleResponse(error, "error");
  }
};

export const getAllBroker = async () => {
  try {
    const responce = await axios({
      url: "/v1/admin/broker",
      method: "GET",
    });
    return handleResponse(responce, "success");
  } catch (error) {
    console.error("error: ", error);
    return handleResponse(error, "error");
  }
};

export const updateBrokerRootAccount = async (data) => {
  try {
    const response = await axios({
      url: `/v1/admin/brokerage-root-account`,
      method: "PUT",
      data,
    })

    return handleResponse(response, "success")
  } catch (error) {
    console.log('error: ', error)
    return handleResponse(error, "error");
  }
}

export const getActiveClients = async (id) => {
  try {
    const response = await axios({
      url: `/v1/admin/active-clients/${id}`,
      method: "GET",
    })

    return handleResponse(response, 'success')
  } catch (error) {
    console.log('error: ', error)
    return handleResponse(error, "error");
  }
}

export const getIndexes = async () => {
  try {
    const response = await axios({
      url: "/v1/admin/indexes",
      method: "GET",
    });

    return handleResponse(response, "success");
  } catch (error) {
    return handleResponse(error, "error");
  }
};

export const getPlaceOrderIndexes = async () => {
  try {
    const response = await axios({
      url: "/v1/admin/placeOrder-indexes",
      method: "GET",
    });

    return handleResponse(response, "success");
  } catch (error) {
    return handleResponse(error, "error");
  }
};

export const updateSession = async (data) => {
  try {
    const responce = await axios({
      url: "/v1/admin/session",
      method: "PUT",
      data,
    });
    return handleResponse(responce, "success");
  } catch (error) {
    console.error("error: ", error);
    return handleResponse(error, "error");
  }
};

export const updateClientDetails = async (data) => {
  try {
    const responce = await axios({
      url: "/v1/admin/client",
      method: "PUT",
      data,
    });
    return handleResponse(responce, "success");
  } catch (error) {
    console.error("error: ", error);
    return handleResponse(error, "error");
  }
};

export const checkSessionToken = async (data) => {
  try {
    const response = await axios({
      url: "/v1/admin/validate-session",
      method: "POST",
      data,
    });

    return handleResponse(response, "success");
  } catch (error) {
    console.log(error);
    return handleResponse(error, "error");
  }
};

export const getPositionsData = async () => {
  try {
    const response = await axios({
      url: "/v1/admin/positions",
      method: "GET",
    });

    return handleResponse(response, "success");
  } catch (error) {
    return handleResponse(error, "error");
  }
};

export const getIndexData = async (index, expiry) => {
  try {
    const response = await axios({
      url: `/v1/admin/index-data/${index}`,
      method: "GET",
      params: { expiry }
    });

    return handleResponse(response, "success");
  } catch (error) {
    return handleResponse(error, "error");
  }
};

export const getIndexIdentifiers = async () => {
  try {
    const response = await axios({
      url: `/v1/admin/index-identifiers`,
      method: "GET",
    });

    return handleResponse(response, "success");
  } catch (error) {
    return handleResponse(error, "error");
  }
};


export const getAllIndexData = async () => {
  try {
    const response = await axios({
      url: `/v1/admin/all-indexes`,
      method: "GET",
    });

    return handleResponse(response, "success");
  } catch (error) {
    return handleResponse(error, "error");
  }
};

export const addIndex = async (data) => {
  try {
    const responce = await axios({
      url: "/v1/admin/index",
      method: "POST",
      data,
    });
    return handleResponse(responce, "success");
  } catch (error) {
    console.error("error: ", error);
    return handleResponse(error, "error");
  }
};
export const postPlaceOrder = async (data) => {
  try {
    const response = await axios({
      url: "/v1/admin/place-order",
      method: "POST",
      data,
    });

    return handleResponse(response, "success");
  } catch (error) {
    return handleResponse(error, "error");
  }
};

export const postStopLoss = async (data) => {
  try {
    const response = await axios({
      url: "/v1/admin/set-stop-loss",
      method: "POST",
      data,
    });

    return handleResponse(response, "success");
  } catch (error) {
    return handleResponse(error, "error");
  }
};

export const postSquareOff = async (data) => {
  try {
    const response = await axios({
      url: "/v1/admin/square-off-group",
      method: "POST",
      data,
    });

    return handleResponse(response, "success");
  } catch (error) {
    return handleResponse(error, "error");
  }
};

export const postReOrder = async (data) => {
  try {
    const response = await axios({
      url: "/v1/admin/re-order",
      method: "POST",
      data,
    });

    return handleResponse(response, "success");
  } catch (error) {
    return handleResponse(error, "error");
  }
};

export const groupAssetTransfer = async (data) => {
  try {
    const response = await axios({
      url: "/v1/admin/group-asset-transfer",
      method: "POST",
      data,
    });

    return handleResponse(response, "success");
  } catch (error) {
    return handleResponse(error, "error");
  }
};

export const updateIndex = async (data) => {
  try {
    const response = await axios({
      url: "/v1/admin/index",
      method: "PUT",
      data,
    });

    return handleResponse(response, "success");
  } catch (error) {
    return handleResponse(error, "error");
  }
};
export const getOrderbook = async (id, broker) => {
  try {
    const response = await axios({
      url: `/v1/admin/orderbook/${id}?broker=${broker}`,
      method: "GET",
    });

    return handleResponse(response, "success");
  } catch (error) {
    return handleResponse(error, "error");
  }
};

export const cancelOrder = async (userId, orderId, broker) => {
  try {
    const response = await axios({
      url: `/v1/admin/cancel-order/${userId}/${orderId}`,
      method: "DELETE",
    });

    return handleResponse(response, 'success')
  } catch (error) {
    return handleResponse(error, 'error')
  }
}

export const cancelAllOrder = async (data) => {
  try {
    const response = await axios({
      url: "/v1/admin/cancel-all-orders",
      method: "POST",
      data,
    });

    return handleResponse(response, "success");
  } catch (error) {
    return handleResponse(error, "error");
  }
};

export const updateUserStatus = async (id, data) => {
  try {
    const response = await axios({
      url: `/v1/admin/user-status/${id}`,
      method: "PUT",
      data,
    });

    return handleResponse(response, "success");
  } catch (error) {
    return handleResponse(error, "error");
  }
};

export const refreshPositionData = async (userId, broker) => {
  try {
    const response = await axios({
      url: `/v1/admin/refresh-user-position/${userId}/${broker}`,
      method: "GET",
    });

    return handleResponse(response, "success");
  } catch (error) {
    return handleResponse(error, "error");
  }
};

export const updateClient = async(id, data) => {
  try {
    const response = await axios({
      url: `/v1/admin/user-type/${id}`,
      method: "PUT",
      data,
    });

    return handleResponse(response, "success");
  } catch (error) {
    return handleResponse(error, "error");
  }
}

export const getMargins = async () => {
  try {
    const response = await axios({
      url: '/v1/admin/margins',
      method: 'GET'
    })

    return handleResponse(response, 'success')
  } catch (error) {
    return handleResponse(error, 'error')
  }
}

export const getTickerData = async () => {
  try {
    const response = await axios({
      url: '/v1/admin/ticker-data',
      method: 'GET'
    });

    return handleResponse(response, 'success');
  } catch (error) {
    return handleResponse(error, 'error');
  }
}

export const newGroup = async(data) => {
  try {
    const response = await axios({
      url: '/v1/admin/groups',
      method: 'POST',
      data
    });

    return handleResponse(response, 'success');
  } catch (error) {
    return handleResponse(error, 'error');
  }
}

export const addComments = async(data) => {
  try {
    const response = await axios({
      url: '/v1/admin/comments',
      method: 'POST',
      data
    });

    return handleResponse(response, 'success');
  } catch (error) {
    return handleResponse(error, 'error');
  }
}

export const getComments = async(id) => {
  try {
    const response = await axios({
      url: `/v1/admin/comments/${id}`,
      method: "GET",
    });

    return handleResponse(response, "success");
  } catch (error) {
    return handleResponse(error, "error");
  }
}

export const readComments = async(user,groupId) => {
  try {
    const response = await axios({
      url: `/v1/admin/read-comments/${user}/${groupId}`,
      method: "PUT",
    });

    return handleResponse(response, "success");
  } catch (error) {
    return handleResponse(error, "error");
  }
}

export const deleteGroup = async(id) => {
  try {
    const response = await axios({
      url: `/v1/admin/groups/${id}`,
      method: "DELETE",
    });

    return handleResponse(response, "success");
  } catch (error) {
    return handleResponse(error, "error");
  }
}

export const updateGroup = async(id, data) => {
  try {
    const response = await axios({
      url: `/v1/admin/groups/${id}`,
      method: "PUT",
      data
    });

    return handleResponse(response, "success");
  } catch (error) {
    return handleResponse(error, "error");
  }
}

export const getClientById = async(clientId) => {
  try {
    const response = await axios({
      url: `/v1/admin/client/${clientId}`,
      method: "GET",
    });

    return handleResponse(response, "success");
  } catch (error) {
    return handleResponse(error, "error");
  }
}