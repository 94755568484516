import React, { useEffect, useState } from "react";
import { Spinner, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { updateIndex } from "../../services/admin.service";
import { useDispatch } from "react-redux";
import { openToast } from "../../redux/slice/toastSlice";
import { FaHome } from "react-icons/fa";
import * as style from "../client/style";
import { capitalize, MenuItem, Select } from "@mui/material";
import {
  getIndexesThunk,
  getPlaceOrderIndexThunk,
} from "../../redux/slice/indexSlice";

const AddIndexTable = ({ data, brokers, fetchData }) => {
  const navigate = useNavigate();
  const [updateData, setUpdateData] = useState(data);
  const [editMode, setEditMode] = useState(null);
  const [initialValues, setInitialValues] = useState({});
  const [loader, setLoader] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    setUpdateData(data);
  }, [data]);

  const handleClick = () => {
    navigate("/add-index");
  };

  const handleEdit = (indexData) => {
    setEditMode(indexData._id);
    const initialFormValues = {
      id: indexData._id,
      name: indexData.name || "",
      dataname: indexData.dataname || "",
      identifier: indexData.identifier || "",
      instrument_token: indexData.instrument_token || "",
      max_order_quantity: indexData.max_order_quantity || "",
      custom_max_order_quantity: indexData.custom_max_order_quantity || "",
      exchange_code: indexData.exchange_code || "",
      lot_size: indexData.lot_size || "",
      margin: indexData.margin || 0,
      symbols: { ...indexData.symbols },
      status: indexData.status || "",
      place_order_status: indexData.place_order_status || "",
      orderBy: parseInt(indexData.orderBy) || 0,
    };
    setInitialValues(initialFormValues);
    formik.setValues(initialFormValues);
  };

  const formik = useFormik({
    initialValues: {
      id: "",
      name: "",
      dataname: "",
      identifier: "",
      instrument_token: "",
      max_order_quantity: "",
      custom_max_order_quantity: "",
      exchange_code: "",
      lot_size: "",
      margin: 0,
      symbols: {},
      status: "",
      place_order_status: "",
      orderBy: 0,
    },
    onSubmit: async (values) => {
      const hasChanges = Object.keys(values).some(
        (key) => values[key] !== initialValues[key]
      );
      if (!hasChanges) {
        setEditMode(null);
        return;
      }

      try {
        setLoader(true);
        const margin = parseFloat(values.margin);
        const { success, message } = await updateIndex({ ...values, margin });
        if (success) {
          setLoader(false);
          setEditMode(null);
          await fetchData();
          if (
            values.status !== initialValues.status ||
            values.orderBy !== initialValues.orderBy
          ) {
            dispatch(getIndexesThunk());
          }

          if (values.place_order_status !== initialValues.place_order_status) {
            dispatch(getPlaceOrderIndexThunk());
          }
        } else {
          setLoader(false);
          setEditMode(null);
          dispatch(
            openToast({
              message: message,
              type: "error",
            })
          );
          setUpdateData(data);
        }
      } catch (error) {
        setLoader(false);
        setEditMode(null);
        console.error("Error updating index:", error);
        setUpdateData(data);
      }
    },
  });

  const handleCancelClick = () => {
    setEditMode(null);
    formik.setValues(initialValues);
  };

  const handleHomeClick = () => {
    navigate("/dashboard");
  };

  return (
    <>
      <style.HeaderWrapper>
        <div>
          <style.Heading>Indexes</style.Heading>
        </div>
        <div className="flex gap-2 items-center">
          <button
            className="items-center button-style h-8 px-2 text-white bg-gray-900 font-OpenSans text-base border-none cursor-pointer focus:outline-none button-style"
            onClick={handleHomeClick}
          >
            <div className="flex items-center justify-center">
              <div>
                <FaHome className="mr-1 align-middle" />
              </div>
              <div>Home</div>
            </div>
          </button>
          <button
            className="flex items-center h-8 px-2 text-white bg-gray-900 font-OpenSans text-base border-none cursor-pointer focus:outline-none button-style"
            onClick={handleClick}
          >
            <style.PlusIcon className="mr-1 align-middle" />
            Add Index
          </button>
        </div>
      </style.HeaderWrapper>

      <style.TableWrapper>
        <div className="ps-cl-container">
          <table className="margin-table">
            <thead className="margin-thead table-head">
              <tr className="margin-tr">
                <th
                  className="align-middle table-head margin-th w-14"
                  rowSpan="2"
                >
                  Sl No
                </th>
                <th className="align-middle table-head margin-th" rowSpan="2">
                  Display Name
                </th>
                <th className="align-middle table-head margin-th" rowSpan="2">
                  Datapoint Name
                </th>
                <th
                  className="align-middle text-center table-head margin-th"
                  rowSpan="2"
                >
                  Identifier
                </th>
                <th
                  className="align-middle text-center table-head margin-th"
                  rowSpan="2"
                >
                  Instrument Token
                </th>
                <th
                  className="align-middle text-center table-head margin-th"
                  rowSpan="2"
                >
                  NSE Max Order Qty
                </th>
                <th
                  className="align-middle text-center table-head margin-th"
                  rowSpan="2"
                >
                  Custom NSE Max Order Qty
                </th>
                <th
                  className="align-middle text-center table-head margin-th"
                  rowSpan="2"
                >
                  Exchange Code
                </th>
                <th
                  className="align-middle text-center table-head margin-th"
                  rowSpan="2"
                >
                  Lot Size
                </th>
                <th
                  className="align-middle text-center table-head margin-th"
                  rowSpan="2"
                >
                  Margin
                </th>
                <th
                  className="align-middle text-center table-head margin-th"
                  rowSpan="2"
                >
                  Status
                </th>
                <th
                  className="align-middle text-center table-head margin-th"
                  rowSpan="2"
                >
                  Place Order Status
                </th>
                <th
                  className="align-middle text-center table-head margin-th"
                  colSpan={brokers.length}
                >
                  Symbols
                </th>
                <th
                  className="align-middle text-center table-head margin-th"
                  rowSpan="2"
                >
                  Actions
                </th>
              </tr>
              <tr className="margin-tr">
                {brokers.map((broker) => (
                  <th
                    className="table-head text-center margin-th"
                    key={broker._id}
                  >
                    {broker.name}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="margin-tbody">
              {updateData?.map((indexData) => {
                const isEditing = editMode === indexData._id;

                return (
                  <tr key={indexData._id} className="margin-tr">
                    <td className="all-normal-text">
                      {isEditing ? (
                        <Form.Control
                          className="index-input all-normal-text"
                          type="number"
                          name="orderBy"
                          value={formik.values.orderBy}
                          onChange={formik.handleChange}
                        />
                      ) : (
                        indexData?.orderBy
                      )}
                    </td>
                    <td className="all-normal-text">
                      {isEditing ? (
                        <Form.Control
                          className="index-input all-normal-text"
                          type="text"
                          name="name"
                          value={formik.values.name}
                          onChange={formik.handleChange}
                        />
                      ) : (
                        indexData?.name?.toUpperCase()
                      )}
                    </td>
                    <td className="all-normal-text">
                      {isEditing ? (
                        <Form.Control
                          className="index-input all-normal-text"
                          type="text"
                          name="dataname"
                          value={formik.values.dataname}
                          onChange={formik.handleChange}
                        />
                      ) : (
                        indexData?.dataname?.toUpperCase()
                      )}
                    </td>
                    {[
                      "identifier",
                      "instrument_token",
                      "max_order_quantity",
                      "custom_max_order_quantity",
                      "exchange_code",
                      "lot_size",
                      "margin",
                    ].map((field) => (
                      <td key={field} className="all-normal-text text-center">
                        {isEditing ? (
                          field === "max_order_quantity" ||
                          field === "custom_max_order_quantity" ||
                          field === "lot_size" ? (
                            <Form.Control
                              className="index-input all-normal-text"
                              type="number"
                              name={field}
                              value={formik.values[field]}
                              onChange={formik.handleChange}
                            />
                          ) : (
                            <Form.Control
                              className="index-input all-normal-text"
                              type="text"
                              name={field}
                              value={formik.values[field]}
                              onChange={formik.handleChange}
                            />
                          )
                        ) : (
                          indexData[field] || ""
                        )}
                      </td>
                    ))}
                    <td className="all-normal-text text-center">
                      {isEditing ? (
                        <Select
                          name="status"
                          value={formik.values.status}
                          className="all-normal-text"
                          onChange={formik.handleChange}
                          SelectDisplayProps={{
                            style: { padding: "4px 32px 4px 10px" },
                          }}
                        >
                          <MenuItem className="all-normal-text" value="active">
                            Active
                          </MenuItem>
                          <MenuItem
                            className="all-normal-text"
                            value="inactive"
                          >
                            Inactive
                          </MenuItem>
                        </Select>
                      ) : (
                        capitalize(indexData.status)
                      )}
                    </td>

                    <td className="all-normal-text text-center">
                      {isEditing ? (
                        <Select
                          name="place_order_status"
                          className="all-normal-text"
                          value={formik.values.place_order_status}
                          onChange={formik.handleChange}
                          SelectDisplayProps={{
                            style: { padding: "4px 32px 4px 10px" },
                          }}
                        >
                          <MenuItem className="all-normal-text" value="active">
                            Active
                          </MenuItem>
                          <MenuItem
                            className="all-normal-text"
                            value="inactive"
                          >
                            Inactive
                          </MenuItem>
                        </Select>
                      ) : (
                        capitalize(indexData.place_order_status)
                      )}
                    </td>

                    {brokers.map((broker) => (
                      <td
                        className="all-normal-text text-center"
                        key={broker._id}
                      >
                        {isEditing ? (
                          <Form.Control
                            className="index-input all-normal-text"
                            type="text"
                            name={`symbols.${broker._id}`}
                            value={formik.values.symbols[broker._id] || ""}
                            onChange={formik.handleChange}
                          />
                        ) : (
                          indexData.symbols[broker._id] || ""
                        )}
                      </td>
                    ))}

                    <td>
                      <style.EditTableButton
                        className="button-style mb-2"
                        onClick={(e) => {
                          e.preventDefault();
                          isEditing
                            ? formik.handleSubmit(e)
                            : handleEdit(indexData);
                        }}
                      >
                        {isEditing ? (
                          loader ? (
                            <Spinner size="sm" />
                          ) : (
                            "Save"
                          )
                        ) : (
                          "Edit"
                        )}
                      </style.EditTableButton>
                      {isEditing && (
                        <style.EditTableButton
                          className="button-style"
                          onClick={handleCancelClick}
                        >
                          Cancel
                        </style.EditTableButton>
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </style.TableWrapper>
    </>
  );
};

export default AddIndexTable;
